import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'patient-ui-duplicate-registration-mfa-setup',
  templateUrl: './duplicate-registration-mfa-setup.component.html',
  styleUrls: ['./duplicate-registration-mfa-setup.component.scss'],
})
export class DuplicateRegistrationMfaSetupComponent {
  @Output() routeToSignInEvent = new EventEmitter<void>();
  constructor(
    public activeModal: NgbActiveModal,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {}

  /**
   * Redirects the user to the landing page
   *
   * This function is used across registration component to handle user redirection
   * after a specific action or process, such as on click on cancel button and close
   * the modal.
   */
  routeToSignIn() {
    this.activeModal.dismiss('cancel');
    this.routeToSignInEvent.emit();
  }

  async login() {
    this.activeModal.dismiss('cancel');
    await this.oktaAuth.signInWithRedirect({ originalUri: '/' });
  }
}
